html {
  /* background: url("./assets/bg.png") no-repeat center center fixed !important;
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
  width: 100%; */
  height: 100% !important;
}

body {
  height: 100% !important;
}

#root {
  height: 100% !important;
}

.theBody {
  height: 100% !important;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#getRestBtn:hover:enabled {
  color: black;
}

.btn2 {
  background-color: black;
  color: white;
}

.btn2:enabled:hover {
  background-color: #a4ffd9 !important;
  color: black !important;
}

h1 {
  font-family: "Baloo Paaji", sans-serif;
}

body {
  font-family: "Poppins", sans-serif !important;
  overflow-x: hidden !important;
  height: 100% !important;
  background: url("./assets/bg.png") no-repeat !important;
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
  width: 100%;
}

.blackText{
  color: "black"
}
/* .sec {
  background: url("./assets/bg.png");
  background-repeat: no-repeat !important;
  height: 100vh;
  background-size: 100%;
} */
::placeholder {
  font-size: 14px;
  color: #999999;
}
.shadow:hover {
  box-shadow: 5px 5px black;
  background-color: #d2bdff !important;
}
.btn1:enabled:hover {
  background-color: #bdffd7 !important;
}
.btn1:disabled {
  border: 1px solid #999999;
  background-color: #b3b3b3;
  color: #666666;
}
.img {
  width: 500px !important;
}

.googleMap{
  width: 100%;
  height: auto;
  border: "0";
}
/* .logo img {
  color: #4d5357 !important;
  height: 20px;
  width: 20px !important;
} */
input:checked + label {
  border-color: black;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #48bb78;
}

input[type="radio"] + label:hover {
  background-color: rgb(192 132 252);
  box-shadow: 5px 5px 0px black;
  /*border-bottom: 5px solid black;
border-right: 5px solid black;
border-top: 3px solid black;*/
}

input[type="radio"]:checked + label {
  background-color: rgb(192 132 252);
  box-shadow: 5px 5px 0px black;
  /*border-bottom: 5px solid black;
border-right: 5px solid black;
border-top: 3px solid black;*/
}
ul {
  margin: 0;
  padding: 0;
}

li {
  font-size: 30px;
  color: #f0f0f0;
  display: inline-block;
  text-shadow: 0 0 1px #666666;
}
.highlight-stars {
  color: #f4b30a;
  text-shadow: 0 0 1px #f4b30a;
}
.h-rs {
  position: relative;
}

.h-rs-index {
  color: gray;
}

.h-rs-container {
  display: flex;
  flex-direction: column;
}

.h-rs-indicator {
  display: block;

  height: 35px;
  border-radius: 12px;
  text-align: center;
  line-height: 35px;

  position: absolute;
  top: 30px;
  left: 0;
  font-weight: 500;
}

.h-rs-line {
  width: 70%;

  -webkit-appearance: none;
  border-radius: 24px;
  background-color: gray;
}

.h-rs-line::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  height: 6px;
  width: 60%;
  border-radius: 24px;
}

.h-rs-line::-webkit-slider-thumb {
  -webkit-appearance: none;
  background: black;
  height: 18px;
  width: 18px;
  border-radius: 100%;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
  margin-top: -5.5px;
}

.h-rs-index {
  display: flex;
  margin-top: 9px;
  width: 300px;
  justify-content: space-between;
}

.logo {
  height: 1.25em;
  width: auto;
}

@media screen and (max-width: 500px) {
  body {
    overflow-x: hidden !important;
  }
  .sec {
    padding-left: 10px;
    padding-right: 10px;
  }
  .h1-like {
    font-size: 30px;
  }
  .textbox {
    width: 85% !important;
  }

  .col2 {
    text-align: center !important;
  }

  .logo img {
    color: #4d5357 !important;
    height: 20px !important;
    width: 20px !important;
  }
}
@media screen and (max-width: 767px) {
  .sec {
    padding-left: 20px;
    padding-right: 20px;
  }
  .h1-like {
    font-size: 35px;
  }
  .textbox {
    width: 60% !important;
  }

  .col2 {
    text-align: center !important;
  }
  .img {
    display: none;
  }
  /* .img {
    height: 350px !important;
    width: 350px !important;
  }
  .logo img {
    color: #4d5357 !important;
    height: 20px !important;
    width: 20px !important;
  } */
}
